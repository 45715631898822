import {
    AnchorProvider, BN, Program
} from '@project-serum/anchor';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { PublicKey } from '@solana/web3.js';
import { useCallback, useEffect, useState } from 'react';
import idl from './idl/backfish.json';
import logo from './img/logo.svg';
import './ReactNav.css';
import useStore from './store';

require('./ReactNav.css');

const programID = new PublicKey("9zJ77vudRw6x5XiTEKzSnp2iPCuhEafiDAT7EcQSYJEj");

const opts = {
  preflightCommitment: "recent",
};

const ReactNav = ({connect, disconnect}) => {
    const account = useStore((state) => state.account);
    const onConnectShow = useStore((state) => state.onConnectShow);
    const setOnConnectShow = useStore((state) => state.setOnConnectShow);

    const handleOnConnectClose = () => setOnConnectShow(false);
    const handleOnConnectShow = (e) => 
    {
        e.preventDefault();
        var icon = document.getElementsByClassName("fa-bars")[0];
        if(!icon)
            toggletoggleMenu();
        setOnConnectShow(true);
    }
    const onConnect = (e) =>{
        e.preventDefault()
        connect()
        handleOnConnectClose()
    }
    const onDisconnect = (e) => {
        e.preventDefault()
        disconnect()
    }
    const toggletoggleMenu = () =>{
        const toggleMenu = document.querySelector('.toggle');
        const navigation = document.querySelector('.navigation');

        toggleMenu.classList.toggle('active');
        navigation.classList.toggle('active');

        var icon = document.getElementsByClassName("fa-bars")[0];
        if(icon){
            icon.className = icon.className.replace("fa-bars", "fa-times");
        }else{
            icon=document.getElementsByClassName("fa-times")[0];
            icon.className = icon.className.replace("fa-times", "fa-bars");
        }
    }
    useEffect(() => {
        const toggleMenu = document.querySelector('.toggle');

        toggleMenu.onclick = () => {
            toggletoggleMenu()
        }

    }, [])

    const connection = useConnection();
    const walletContext = useWallet();
    console.log(connection.connection.rpcEndpoint);
    const onClickTest = useCallback(async()=>{
        const provider = new AnchorProvider(
            connection.connection, walletContext, "processed"//opts.preflightCommitment,
          )

        // anchor idl init 으로 체인에 등록처리된 경우에만 사용가능함 (idl이 체인에 업로드된 경우)
        const idlFromChain = await Program.fetchIdl(programID, provider);
        console.log(idlFromChain);
        var idlcurrent = idlFromChain ? idlFromChain : idl;
        const program = new Program(idlcurrent, programID, provider);
        var result = await program.methods.initialize(new BN(1))
        .accounts({})
        // .signers([localAccount])
        .rpc();
        alert("resultTx = " + result + "\n publickey = " + walletContext.publicKey + "\n connected = " + walletContext.connected + "\n adapter = " + walletContext.wallet.adapter.name);
    });

    return (
        <header>
            <a href="/" className="logo"><img src={logo} /></a>
            <div className="toggle"><i className="fas fa-bars"></i></div>
            <ul className="navigation">
                <li><a href="#story">FishStory</a></li>
                <li><a href="#mint">BuyFish</a></li>
                <li><a href="#roadmap">Roadmap</a></li>
                <li><a href="#rarity">Rarity</a></li>
                <li><a href="#community">Community</a></li>
                <li><a href="#reward">Wallet</a></li>
                <li><a href="#" onClick={onClickTest}>TestWallet</a></li>
                {/* <li>
                    {!account && <a href="#" id="connect" onClick={handleOnConnectShow}>
                        <span>Connect</span>
                    </a>}
                    {account && <a href="#" id="account" onClick={onDisconnect}>
                        <i className="fas fa-check"></i><span>{account}</span>
                    </a>}
                </li> */}
                <li>
                    <Content />
                </li>
            </ul>
            {/* <Modal show={onConnectShow} onHide={handleOnConnectClose} 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body>
                    <div className='text-center'>
                        <div className='row logincontainer'>
                            <div className='col-md-12'>
                                <div className='loginbanner'>
                                    <a  href="#" onClick={onConnect}>
                                        <img src={walletIcon}></img>
                                        <h1>Phantom</h1>
                                        <p className='text-muted'>Connect with phantom wallet</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> */}
        </header>
     );
}
 
export default ReactNav;

var Content = function () {
    return (
      <div className="solana-wallet-select-button">
          <WalletMultiButton />
      </div>
      );
};