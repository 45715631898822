import './FishStory.css';
import storypic from '../img/story/story.png';
const FishStory = () => {
    return ( 
        <div className="story">
            <div className="pagegap" />
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="imageframe">
                            <img src={storypic} />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="description">
                            <div className="title">
                                <h2>Episode. 1</h2>
                                <h1><span> We Are Atlantis Salary Man </span></h1>
                            </div>
                            <div>
                                <p>A long time ago in a Atlantis far far away... Too many trashs are ruined blue ocean. Some fishs are fight against evil Junk Empire. During the battle, Atlantis spies managed to steal secret plans to the Empire’s ultimate weapon, the DEATH STAR, an armored Great Pacific Garbage Patch with enough power to destroy an entire planet. Pursued by the Empire’s sinister agents, Princess Leia races home aboard her starship, custodian of the stolen plans that can save her people and restore freedom to the galaxy….</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pagegap" />
        </div>
     );
}
 
export default FishStory;