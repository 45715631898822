import './LDescription.css'
import title from '../img/logo.svg';
import jellyfish from '../img/jellyfish.png';
const SDescription = () => {
    return ( 
        <div className="ldescription">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="panel">
                            <div className="title"><h1><span>&nbsp;Our Team Is...&nbsp;</span></h1></div>
                            <p>
                                The 'Rush Hour' project team was born as a result of the collaboration of Korean content industry workers who want to make a positive impact on the world.
                                'Fish Hour' is the first project among them, and it works to protect the marine ecosystem.
                                'Fish Hour' consists of a total of 10,000 NFT collections.
                                These collections are compared to the tired faces of office workers who ride the subway after leaving the global ecosystem, exhausted from environmental pollution.
                                We are waiting for the participation of many who will bring a smile to our weary planet. :)
                            </p>
                            <div className="logo">
                                <div className="text-center">
                                    <img src={title} />
                                </div>
                            </div>
                        </div>
                        <div className="jellyfish"><img src={jellyfish}/></div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default SDescription;