import create from 'zustand';

const useStore = create((set) => ({
    totalSupply: 0,
    maxSupply: 10000,
    account: null,
    contract: null,
    canvasWidth: 0,
    canvasHeight: 0,
    onConnectShow: false, 
    setOnConnectShow: (input) => set((state) => ({onConnectShow: input})),
    setTotalSupply: (input) => set((state) => ({totalSupply: input})),
    setMaxlSupply: (input) => set((state) => ({maxSupply: input})),
    setAccount: (input) => set((state) => ({account: input})),
    setContract: (input) => set((state) => ({contract: input})),
    setCanvasWidth: (input) => set((state) => ({canvasWidth: input})),
    setCanvasHeight: (input) => set((state) => ({canvasHeight: input}))
  }));

export default useStore;