import './Community.css'
import osIcon from "../img/oslogo.svg"
import meIcon from "../img/MagicEdenlogo.png"
import endBackground from "../img/Last_background.png"
const Community = () => {
    return ( 
        <div className="community" id='community'>
            <div className="pagegap" />
            <div className="panelback"></div>
            <div className="panel">
                <div className="title">
                    <h1 className='text-center'>Community</h1>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <a href="#" onClick={function(e){e.preventDefault();}}>
                                <div className="communitybtn" id='cmtbtn0' >
                                    <img src={meIcon}/>
                                    <h5 className='os' id='me1'><span>view on magic eden</span></h5>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-10 offset-md-1">
                            <a href="#" onClick={function(e){e.preventDefault();}}>
                                <div className="communitybtn" id='cmtbtn1' >
                                    <img src={osIcon}/>
                                    <h5 className='os' id='os1'>view on opensea</h5>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-10 offset-md-1">
                            <a href="#" onClick={function(e){e.preventDefault();}}>
                                <div className="communitybtn" id='cmtbtn2' >
                                    <h4><i className="fab fa-discord"></i></h4>
                                    <h5 className='os'>discord</h5>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-10 offset-md-1">
                            <a href="#" onClick={function(e){e.preventDefault();}}>
                                <div className="communitybtn" id='cmtbtn3' >
                                    <h4><i className="fab fa-twitter"></i></h4>
                                    <h5 className='os'>twitter</h5>
                                    
                                </div>
                                
                            </a>
                            
                        </div>
                    </div>
                </div>
                
            </div>
            
            <div className="endBackground">
                <img src={endBackground}/>
            </div>
        </div>
     );
}
 
export default Community;