import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import React, { FC, useEffect, useMemo } from 'react';
import Home from './Home';
import ReactNav from './ReactNav';
import useStore from './store';
import Bubble from './Bubble';

import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, useWallet, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { GlowWalletAdapter, PhantomWalletAdapter, SlopeWalletAdapter, SolflareWalletAdapter, TorusWalletAdapter, } from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// require('./App.css');
require('@solana/wallet-adapter-react-ui/styles.css');

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDqixPJOemCD1wPIpvrYXIoDaKpLCOwbls",
  authDomain: "test-e148b.firebaseapp.com",
  projectId: "test-e148b",
  storageBucket: "test-e148b.appspot.com",
  messagingSenderId: "571468417580",
  appId: "1:571468417580:web:f7e8d7b77e3212d6bb1f89",
  measurementId: "G-JD68SMGZPR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App(){
  const account = useStore((state) => state.account);
  const setAccount = useStore((state) => state.setAccount);
  const contract = useStore((state) => state.contract);
  const setContract = useStore((state) => state.setContract);
  const onConnectShow = useStore((state) => state.onConnectShow);
  const setOnConnectShow = useStore((state) => state.setOnConnectShow);

  useEffect(()=>{
    const script = document.createElement("script");
    script.src = "https://kit.fontawesome.com/fceca2c0bb.js";
    script.async = true;
    document.body.appendChild(script);

    //TODO GetContract info and account info

    setAccount('0x47e6206571a7dA82964a0657435409Cfc2D240C6');
    setContract('17e0cea163f0273bb8f655596fa08ccb9b4661fdb5572f383a47dff6910c906b');
  },[])

  const mintToken = async () =>{
    try{
      //ex) 토큰 민팅 로그 남기기
      logEvent(analytics, 'mintToken!', {id:"asdf", count:"1"});
      const wallets = window.solana;
      window.alert('Token minted! wallet=' + wallets._publicKey);
    }catch(e){
    }
  }
  const connectshow = (e) =>{
    setOnConnectShow(true);
  }
  const connect = (e) =>{
    setAccount('0x47e6206571a7dA82964a0657435409Cfc2D240C6');
  }
  const disconnect = (e) =>{
    setAccount(null);
  }

  return (
    <Context>
      <Router>
        <div className="App">
          <Bubble />
          <ReactNav connect = {connect} disconnect = {disconnect}/>
          <Switch>
            <Route exact path="/">
              <Home mintToken = {mintToken} connectshow = {connectshow}/>
            </Route>
          </Switch> 
        </div>
      </Router>
      {/* <Content/> */}
    </Context>
  );
}

export default App;
var Context = function (reactNode) {
  var children = reactNode.children;
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  var network = WalletAdapterNetwork.Devnet;
  // You can also provide a custom RPC endpoint.
  
  //FIXME 네트워크 하드코딩 vs ui로 선택되게?
  // var endpoint = "http://127.0.0.1:8899";
  var endpoint = useMemo(function () { return clusterApiUrl(network); }, [network]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  var wallets = useMemo(function () { return [
      new PhantomWalletAdapter(),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network: network }),
      new TorusWalletAdapter(),
  ]; }, [network]);
  
  return (
    <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
            <WalletModalProvider>
              {children}
            </WalletModalProvider>
        </WalletProvider>
    </ConnectionProvider>
    );
};
// var Content = function () {
//   return (
//     <div className="App">
//         <WalletMultiButton />
//     </div>
//     );
// };