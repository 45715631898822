import { FC } from 'react';
import roadmap30_1 from '../img/roadmap/Group 10.png';
import roadmap30_2 from '../img/roadmap/Group 11.png';
import roadmap50_1 from '../img/roadmap/Group 13.png';
import roadmap50_2 from '../img/roadmap/Group 14.png';
import './Roadmap.css';

const Roadmap: FC = ()=>{
    return ( 
                <div className='roadmap' id='roadmap'>
                    {/* <div className="object">
                        <img src={object1}/>
                    </div> */}
                    <div className='topbumper'></div>
                    <div className="container">
                        <div>
                            <h1 className='text-center'>Road map</h1>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <div className="title" id='title1'><h3>SOLD</h3><h3 id='percentage'>30%</h3></div>
                                {/* <div className="commingsoon">
                                    <span className='align-middle'>
                                    <h1 className='text-center'>
                                        Comming Soon
                                    </h1>
                                    </span>
                                </div> */}
                                <div className="panel">
                                    <img src={roadmap30_1}/>
                                </div>
                                <div className="panel">
                                    <img src={roadmap30_2}/>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <div className="title" id='title2'><h3>SOLD</h3><h3 id='percentage'>50%</h3></div>
                                <div className="panel">
                                    <img src={roadmap50_1}/>
                                </div>
                                <div className="panel">
                                    <img src={roadmap50_2}/>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <div className="title" id='title3'><h3>SOLD</h3><h3 id='percentage'>70%</h3></div>
                                {/* <div className="panel"></div>
                                <div className="panel"></div> */}
                                <div className="commingsoon">
                                    <span className='align-middle'>
                                    <h1 className='text-center'>
                                        Comming Soon
                                    </h1>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            );
}

// const Roadmap = () => {
//     return ( 
//         <div className='roadmap' id='roadmap'>
//             {/* <div className="object">
//                 <img src={object1}/>
//             </div> */}
//             <div className='topbumper'></div>
//             <div className="container">
//                 <div>
//                     <h1 className='text-center'>Road map</h1>
//                 </div>
//                 <div className="row">
//                     <div className="col-lg-4 col-md-12 col-sm-12">
//                         <div className="title" id='title1'><h3>SOLD</h3><h3 id='percentage'>30%</h3></div>
//                         {/* <div className="commingsoon">
//                             <span className='align-middle'>
//                             <h1 className='text-center'>
//                                 Comming Soon
//                             </h1>
//                             </span>
//                         </div> */}
//                         <div className="panel">
//                             <img src={roadmap30_1}/>
//                         </div>
//                         <div className="panel">
//                             <img src={roadmap30_2}/>
//                         </div>
//                     </div>
//                     <div className="col-lg-4 col-md-12 col-sm-12">
//                         <div className="title" id='title2'><h3>SOLD</h3><h3 id='percentage'>50%</h3></div>
//                         <div className="panel">
//                             <img src={roadmap50_1}/>
//                         </div>
//                         <div className="panel">
//                             <img src={roadmap50_2}/>
//                         </div>
//                     </div>
//                     <div className="col-lg-4 col-md-12 col-sm-12">
//                         <div className="title" id='title3'><h3>SOLD</h3><h3 id='percentage'>70%</h3></div>
//                         {/* <div className="panel"></div>
//                         <div className="panel"></div> */}
//                         <div className="commingsoon">
//                             <span className='align-middle'>
//                             <h1 className='text-center'>
//                                 Comming Soon
//                             </h1>
//                             </span>
//                         </div>
//                     </div>
//                 </div>
//             </div>
            
//         </div>
//      );
// }
 
export default Roadmap;