import train from '../img/train.gif'
import title from '../img/title.png'
import weed from '../img/weed.png'
import './Welcome.css'
import './MouseScroll.css'

const Welcome = () => {
    return ( 
        <div className="welcome">
            <div className='title'>
                <img src={title}/>
                <div className='subTitle'>
                    <h6 className='text-center'>in Atlantis City</h6>
                </div>
            </div>
            <img src={train}/>
            <img src={weed} id="weed1"/>
            <img src={weed} id="weed2"/>
            <p></p>
            <div className="scroll">
                <div className="mouse_scroll">
                    <div className="mouse">
                        <div className="wheel"></div>
                    </div>
                    <div>
                        <span className="m_scroll_arrows unu"></span>
                        <span className="m_scroll_arrows doi"></span>
                        
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Welcome;