import Buyfish from './Component/Buyfish';
import Community from './Component/Community';
import FishStory from './Component/FishStory';
import LDescription from './Component/LDescription';
import Rarity from './Component/Rarity';
import Roadmap from './Component/Roadmap';
import SDescription from './Component/SDescription';
import Teams from './Component/Teams';
import Welcome from './Component/Welcome';

const Home = ({mintToken, connectshow}) => {
    return ( 
        <div>
            <Welcome />
            <FishStory />
            {/* <FishStoryStar /> */}
            <Buyfish mintToken={mintToken} connectshow={connectshow}/>
            <SDescription />
            <Roadmap />
            <Rarity />
            <LDescription />
            <Teams />
            <Community />
        </div>
     );
}
 
export default Home;
