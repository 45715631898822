import { useState, useEffect } from 'react';
import agent from '../img/agent.png'
import episode from '../img/buyfish/episode.svg'
import preview from '../img/preview.png'
import preview1 from '../img/preview1.png'
import solanalogo from '../img/solanaLogoMark.svg'
import './Buyfish.css'
import useStore from '../store'
import gotome from '../img/buyfish/gotome.svg'
import gotoos from '../img/buyfish/gotoos.svg'

const Buyfish = ({mintToken, connectshow}) => {
    const totalSupply = useStore((state) => state.totalSupply);
    const maxSupply = useStore((state) => state.maxSupply);
    const account = useStore((state) => state.account);

    const buyButton = (e) => {
        e.preventDefault();
        mintToken();
    }
    const onConnect = (e) =>{
        e.preventDefault();
        connectshow();
    }
    return ( 
        <div className='buyfish' id="mint">
            <img src={preview} className='gallery' id='gallery1'/>
            <img src={preview1} className='gallery' id='gallery2'/>
            <div className="files">
                <img src={agent}/>
                <div className="episode">
                    <img src={episode}/>
                    <h1>EP.1</h1>
                </div>
            </div>
            <div className="progress" style={{height:'32px', backgroundColor: '#424242', borderRadius: '16px'}}>
                <div className="progress-bar" style={{width: (totalSupply / maxSupply * 100) + '%'}} role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                <div className="mintcounter">
                    {account && <h2 className='text-center'>{Number(totalSupply).toLocaleString()}/{Number(maxSupply).toLocaleString()}</h2> }
                    {!account && <h2 className='text-center'>Login is required</h2>}
                </div>
            </div>
            <div className="minted"><h3>minted</h3></div>
            {account && <div className="buybtn">
                <a href="#" onClick={buyButton}>
                    <div className="buybtndiv">
                        <div className="col-20">
                            <h3><img src={solanalogo}></img></h3>
                        </div>
                        <div className="col-80 text-center">
                            <h4>buy fish</h4>
                            <h5>1.0 SOL</h5>
                        </div>
                    </div>
                </a>
            </div>}
            {!account && <div className="connectbtn">
                <a href="#" onClick={onConnect}>
                    <div className="connectbtndiv">
                        <h1>Connect</h1>
                    </div>
                </a>
            </div>}
            <div className="marketbtn">
                <div className="marketbtndiv">
                    <div className="gotome">
                        <a href="#">
                            <img src={gotome}/>
                        </a>
                    </div>
                    <div className="gotoos">
                        <a href="#">
                            <img src={gotoos}/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Buyfish;