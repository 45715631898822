import profile from '../img/fish.png'
import './Rarity.css'
import object2 from '../img/trash2.png'
const Rarity = () => {
    return ( 
       
        <div className="rarity" id='rarity'>
            <div className="pagegap" />
            <div className="topbumper" />
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <div className="panel">
                            <div className="title">
                                <img src={profile}/>
                                <h1 className='text-center'>Rarity</h1>
                            </div>
                            <div className="titlebumper" />
                            <div className="raritylist">
                                <h4>background</h4>
                                <h5>13</h5>
                            </div>
                            <div className="raritylist">
                                <h4>handle</h4>
                                <h5>8</h5>
                            </div>
                            <div className="raritylist">
                                <h4>face</h4>
                                <h5>27</h5>
                            </div>
                            <div className="raritylist">
                                <h4>suit</h4>
                                <h5>23</h5>
                            </div>
                            <div className="raritylist">
                                <h4>tie</h4>
                                <h5>13</h5>
                            </div>
                            <div className="raritylist">
                                <h4>eye</h4>
                                <h5>18</h5>
                            </div>
                            <div className="raritylist">
                                <h4>bag</h4>
                                <h5>6</h5>
                            </div>
                            <div className="raritylist">
                                <h4>mouth</h4>
                                <h5>17</h5>
                            </div>
                            <div className="raritylist">
                                <h4>emotion</h4>
                                <h5>3</h5>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="pagegap" />
        </div>
        
     );
}
 
export default Rarity;