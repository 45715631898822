import './Teams.css';
import bg from "../img/teams/deco.png"
import hyun from "../img/teams/hyun.png"
import gyum from "../img/teams/gyum.png"
import wong from "../img/teams/wong.png"
const Teams = () => {
    return ( 
        <div className="teams">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="paneldiv">
                            <img src={hyun}/>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="paneldiv">
                            <img src={gyum}/>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="paneldiv">
                            <img src={wong}/>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* <div className="teamsbg">
                <img src={bg}/>
            </div> */}
        </div>
     );
}
 
export default Teams;