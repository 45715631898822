import { useState, useEffect } from 'react';
import bicycle from '../img/trash1.png';
import solscan from '../img/solscanlogo.png';
import title from '../img/logo.svg';
import './SDescription.css';
import useStore from '../store';

const SDescription = () => {
    const contract = useStore((state) => state.contract);

    useEffect(()=>{
    
    },[])
    return ( 
        <div className="sdescription">
            <div className="pagegap" />
            <div className="container">
                <div className="row">
                    <div className="col-md-5 col-sm-12">
                        <span className='align-middle'>
                            <img src={bicycle}/>
                        </span>
                    </div>
                    <div className="col-md-7 col-sm-12">
                        <div className="panel">
                            <span className='align-middle'>
                                <div className='alignhelper'>
                                    <h1>Fish Hour Is...</h1>
                                    <p>
                                        'Fish Hour' is an NFT project for the public interest that contributes to the protection of <span>marine ecosystems.</span> 
                                        A portion of the participation proceeds will be <span>donated</span> to the actual marine ecosystem protection campaign.
                                    </p>
                                    <div className="contract">
                                        <h5><img src={solscan}/> Contract Details:</h5>
                                        <a href={'https://solscan.io/collection/' + contract} target={'_blank'}><p>{contract}</p></a>
                                    </div>
                                    <div className="logo">
                                        <div className="text-center">
                                            <img src={title} />
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pagegap" />
        </div>
     );
}
 
export default SDescription;